<template>
	<div>
		<nav class="main fix-on-scroll" :class="page && page.headingImage ? '' : 'no-slider'">
			<div class="row">
				<div class="columns column12 flex-row">
					<div v-if="showMenu" class="menu-button button" @click="hideMenu">
						<font-awesome-icon icon="fa-regular fa-times" size="1x" />
						<span>{{ $t('close') }}</span>
					</div>
					<div v-else class="menu-button button" @click="toggleMenu">
						<font-awesome-icon icon="fa-regular fa-bars" size="1x" />
						<span>{{ $t('menu') }}</span>
					</div>
					<nuxt-link class="logo" aria-label="Back to homepage" :to="localePath('/')" @click="hideMenu" />
					<div v-click-outside="closeLangNav" class="nav-buttons">
						<div class="lang-select" @click="toggleLangNav">
							<span>{{ locale }}</span>
							<font-awesome-icon icon="fa-light fa-chevron-down" size="1x" />
							<div v-if="showLangNav" class="lang-wrapper">
								<div v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
									<nuxt-link
										class="flag"
										:class="item.language"
										:to="`${item.filename}`"
										@click="hideMenu"
									>
										{{ item.language }}
									</nuxt-link>
								</div>
							</div>
						</div>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
						</a>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
						</a>
						<book-button class="button button-cta reserve-now right" aria-label="Reserve now">
							<font-awesome-icon icon="fa-light fa-calendar-days" size="1x" />
							<span>{{ $t('bookNow') }}</span>
						</book-button>
					</div>
				</div>
			</div>
			<div class="nav-container" :class="{ active: showMenu }">
				<div class="row">
					<ul class="main-nav">
						<li
							v-for="item in topmenu[locale]"
							:key="item.filename"
							:class="{ 'has-dropdown': item.submenu.length }"
						>
							<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
								{{ item.header }}
							</nuxt-link>
							<submenu
								v-if="item.submenu.length"
								:hide-all="!showMenu"
								:item="item"
								:subitems="item.submenu"
								@hide-menu="hideMenu"
							/>
						</li>
						<li v-if="page" class="lang-nav">
							<div v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
								<nuxt-link
									class="flag"
									:class="item.language"
									:to="`${item.filename}`"
									@click="hideMenu"
								>
									<span>{{ $t(item.language) }}</span>
								</nuxt-link>
							</div>
						</li>
					</ul>
					<div class="nav-contact">
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
							<span> {{ defaults[locale].website.phone }}</span>
						</a>
						<social-media :socials="socials" />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
							<span> {{ defaults[locale].website.email }}</span>
						</a>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const showLangNav = ref(false);
const showMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const toggleLangNav = () => {
	showLangNav.value = !showLangNav.value;
};

const closeLangNav = () => {
	showLangNav.value = false;
};

const toggleMenu = () => {
	showMenu.value = !showMenu.value;
	document.body.classList.add('locked');
};

const hideMenu = () => {
	showMenu.value = false;
	document.body.classList.remove('locked');
};
</script>

<style lang="scss" scoped>
.logo {
	background: url('~/assets/images/logo-witteberken-white-full.svg') no-repeat center center;
	background-size: 210px;
	width: 210px;
	height: 107px;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	animation: fade-from-top 1s ease;
	transition: all 0.3s ease-in-out;
}

nav.main {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	padding: 40px 0;
	transition: all 0.3s ease-in-out;
	overflow: visible;
	background: rgba(0 0 0 / 50%);
	background: linear-gradient(0deg, rgba(0 0 0 / 0%) 5%, rgba(0 0 0 / 30%) 45%, rgba(0 0 0 / 75%) 100%);
}

nav .row {
	overflow: visible;
	z-index: 102;
	position: relative;
}

.flag::before {
	content: '';
	width: 16px;
	position: relative;
	top: -1px;
	height: 16px;
	display: inline-block;
}

.flag.en::before {
	background: url('~/assets/images/en.png') no-repeat;
}

.flag.nl::before {
	background: url('~/assets/images/nl.png') no-repeat;
}

.flag.de::before {
	background: url('~/assets/images/de.png') no-repeat;
}

.flag.fr::before {
	background: url('~/assets/images/fr.png') no-repeat;
}

.flag.es::before {
	background: url('~/assets/images/es.png') no-repeat;
}

nav.main.scroll,
nav.main.no-slider {
	padding: 18px;
	background: rgb(135 158 142 / 90%);

	.logo {
		background: url('~/assets/images/logo-witteberken-white-full.svg') no-repeat top center;
		background-size: 210px;
		height: 62px;
	}
}

.menu-button {
	background: none;
	padding: 0;
	margin: 0;
	font-size: 22px;
	font-weight: 400;
	cursor: pointer;
	text-align: left;
	animation: fade-from-left 0.7s ease;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	opacity: 0.85;

	span {
		margin: 2px 0 0;
	}

	&:hover {
		opacity: 1;
		background: none;
	}
}

nav .flex-row,
.nav-buttons {
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.nav-buttons {
	gap: 22px;
	color: #fff;
	animation: fade-from-right 0.7s ease;

	a {
		color: #fff;
		text-decoration: none;
		margin: 0;
		opacity: 0.85;

		&.button,
		&:hover {
			opacity: 1;
		}

		&.reserve-now {
			background: #fff;
			color: $cta-color;

			&:hover {
				color: $cta-color-hover;
				background: $contentblock-background-color;
			}
		}
	}
}

.reserve-now {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	padding: 10px 25px 10px 20px;

	svg {
		margin: 0 12px 2px 0;
	}
}

.nav-container {
	background: rgba(36 46 58 / 80%);
	backdrop-filter: blur(38px);
	padding: 30px 50px;
	position: fixed;
	top: 0;
	left: 0;
	padding-top: 80px;
	z-index: 101;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.4s ease-in-out;
	transform: translate(0, 100%) matrix(1, 0, 0, 1, 0, 0);

	.row {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}
}

.nav-container.active {
	transform: matrix(1, 0, 0, 1, 0, 0);
	opacity: 1;
}

.lang-nav {
	display: none;
	list-style: none;
	text-align: left;
	float: left;
	width: 50%;
	line-height: 26px;
	padding-top: 40px;

	a {
		color: #fff;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;
		font-size: 16px !important;
		gap: 5px;

		&.router-link-exact-active,
		&:hover {
			color: $cta-color;
		}
	}

	img {
		max-width: 16px;
		float: left;
		margin: 0 5px 0 0;
	}
}

.lang-select {
	padding: 12px 15px;
	border-right: 1px solid $alternating-background-color;
	cursor: pointer;
	position: relative;
	text-transform: uppercase;

	svg {
		margin-left: 3px;
		font-size: 12px;
	}
}

.lang-wrapper {
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 10px 9px;
	text-align: center;
	background: #fff;
	box-shadow: 0 18px 18px rgba(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;

	a {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		color: #000;
		gap: 5px;
		font-size: 13px;
		margin: 5px 0;
	}

	img {
		width: 16px;
		height: 16px;
		margin: 0 0 2px;
	}
}

.lang-wrapper.active {
	display: flex;
	flex-flow: column nowrap;
	place-content: space-between flex-start;
	color: #000;
	gap: 5px;
}

.mobile-lang-nav {
	margin: 40px 0 0;
	font-size: 14px;
	display: none;

	img {
		max-width: 16px;
		margin: 0 10px 0 0;
	}
}

.main-nav .mobile-lang-nav a {
	width: 100%;
	display: block;
	font-size: 16px;
	line-height: 28px;
}

/* Fullscreen Nav */
.main-nav {
	list-style: none;
	min-width: 25%;
	text-align: center;
	padding-top: 100px;

	a {
		color: #fff;
		font-size: 32px;
		line-height: 54px;
		font-weight: 400;

		&:hover,
		&.active {
			color: $cta-color;
		}
	}
}

.nav-contact {
	align-self: flex-end;
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	gap: 20px;
	border-top: 1px solid rgba(255 255 255 / 30%);
	padding: 20px 0 0;

	.socials {
		margin: 0;
	}
}

.nav-contact > a {
	color: #fff;
	display: block;
	font-weight: 300;
	margin: 0;
}

.nav-contact > a i {
	margin: 0 10px 0 0;
}

.close-wrapper {
	align-self: flex-start;
}

.nav-close {
	color: #fff;
	cursor: pointer;
	font-size: 54px;
	padding: 0 0 0 12px;
}

/* Breadcrumbs */
.breadcrumbs ul {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin: 0 0 75px;
}

.breadcrumbs li {
	margin: 0 10px 0 0;
}

.breadcrumbs i {
	font-size: 11px;
}

.breadcrumbs a {
	color: $body-color;
}

.breadcrumbs a.active {
	color: $cta-color;
}

@media (max-height: 740px) {
	.nav-container {
		overflow-y: scroll;
	}

	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		z-index: 1;
	}

	.main-nav {
		z-index: 2;
		position: relative;
	}
}

@media (max-width: 920px) {
	.reserve-now {
		padding: 0;

		svg {
			margin: 0;
		}
	}

	.menu-button {
		justify-content: center;
	}

	.lang-nav {
		display: block;
	}

	.close-wrapper {
		align-self: flex-end;
	}
}

@media (max-width: 800px) {
	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		float: left;
		margin-top: 30px;
	}

	.main-nav {
		a {
			font-size: 22px;
			line-height: 44px;
		}
	}

	.nav-contact {
		flex-flow: column wrap;
	}
}

@media (max-width: 600px) {
	.nav-container {
		padding: 10px 25px 25px;
	}

	.main-nav,
	.contact-info,
	.lang-nav {
		width: 100%;
	}

	.main-nav {
		padding-top: 140px;
	}

	nav.main {
		.flex-row {
			padding: 0;
		}
	}

	nav.main.scroll,
	nav.main.no-slider {
		padding: 18px 6px;
	}

	.contact-info {
		margin: 25px auto 0;
	}

	.lang-nav {
		margin-top: 0;
	}

	nav .buttons .button {
		svg {
			margin-right: 0;
			font-size: 16px;
		}

		span {
			display: none;
		}
	}
}
</style>
