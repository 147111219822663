<template>
	<span>
		<span class="mobile-dropdown" :class="{ active }" @click="active = !active" />
		<slide-up-down :active="hideAll ? resetState() : active" :duration="300">
			<ul class="submenu">
				<li v-for="item in subitems" :key="item.filename">
					<nuxt-link :to="`${item.filename}`" @click="hideMenu">{{ item.header }}</nuxt-link>
				</li>
			</ul>
		</slide-up-down>
	</span>
</template>

<script setup>
defineProps({
	subitems: { type: Array, default: () => [] },
	hideAll: { type: Boolean, default: false },
});

const emit = defineEmits(['hide-mobile-nav']);

const active = ref(false);

const resetState = () => {
	active.value = false;
};

const hideMenu = () => {
	emit('hide-mobile-nav');
};
</script>

<style lang="scss" scoped>
a {
	font-weight: 400;
	color: #fff;

	&.active,
	&:hover {
		color: $cta-color;
	}
}

.mobile-dropdown {
	width: 22px;
	height: 22px;
	background: url('~/assets/images/icon-dropdown-white.png') center center no-repeat;
	line-height: 22px;
	vertical-align: middle;
	display: inline-block;
	cursor: pointer;
	margin-top: -3px;

	&.active {
		transform: rotate(180deg);
	}
}

.submenu {
	list-style: none;
	padding: 10px 0 0;

	&.active {
		display: block;
	}

	li,
	li a {
		white-space: nowrap;
		font-size: 18px;
		line-height: 32px;
	}
}
</style>
