<template>
	<a class="to-top" @click="scrollToTop()">
		<font-awesome-icon icon="fa-light fa-chevron-up" size="1x" />
	</a>
</template>

<script setup>
const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.to-top');
	if (window.pageYOffset >= 350) {
		fixedElements.forEach((el) => el.classList.add('show'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('show'));
	}
};

const scrollToTop = () => {
	window.scroll({
		top: 0,
		left: 0,
		behavior: 'smooth',
	});
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
.to-top {
	position: fixed;
	bottom: 75px;
	left: 15px;
	opacity: 0;
	background: #fff;
	color: #333;
	width: 42px;
	height: 42px;
	text-align: center;
	line-height: 42px;
	cursor: pointer;
	border-radius: 50%;
	z-index: 99;
	box-shadow: 0 0 15px rgb(0 0 0 / 15%);

	&:hover {
		color: $cta-color;
	}

	&.show {
		opacity: 1;
	}

	span {
		border-top: 1px solid #333;
		border-right: 1px solid #333;
		width: 10px;
		height: 10px;
		transform: rotate(-45deg);
		display: block;
	}
}

@media (max-width: 600px) {
	.to-top {
		bottom: 70px;
	}
}
</style>
